.timelines {
    height: calc(100vh - 461px);
    overflow-y: scroll;
    min-height: 440px;
}
.timeline__elements {
    margin-right: 10px;
    display: flex;
    margin-bottom: 10px;

    .type {
        min-width: 110px;
        display: inline-block;
        padding: 9px;
    }


    .card-info {
        padding: 10px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 12px;
        .info {
            line-height: 20px;
        }
        .field {
            line-height: 20px;
            font-weight: bolder;
        }
    }
    .timeline__content {
        justify-content: space-between;
        width: 100%;
        display: flex;


        textarea {
            line-height: normal;
            margin-top: 0px;
            margin-bottom: 0px;
            border-radius: 7px;
            padding: 6px;
        }
        .text {
            line-height: 34px;
            padding-left: 17px;
        }
        .date {
            min-width: 105px;
            line-height: 34px;
            padding-right: 17px;
        }

    }

}
.card-porta-info {
    padding: 18px;
    background: #e9ecef;
    border: 1px solid #b62e23;
    min-height: 440px;
}
tr.checked {
    background: #b62e23;
    color: #fff;
    .btn-r {
        color: #000 !important;
        width: 33px;
        height: 33px;
        background: #fff;
        line-height: 25px;
        text-align: center;
    }
}